<template>
  <div>
    <b-card>
      <b-input-group class="mb-2">
        <b-input-group-prepend>
          <b-button variant="primary" @click="search()">
            <i class="fa fa-search"></i> 검색
          </b-button>
        </b-input-group-prepend>
        <b-form-input type="text" placeholder="검색어를 입력해주세요" v-model="originSearch" @keypress.enter.prevent.stop="search()" v-focus></b-form-input>
      </b-input-group>
    </b-card>
    <b-row style="position: relative;">
      <b-col md="12">
        <b-card>
          <div class="mt-1" slot="header">
            <strong>원산지 조회/등록 </strong>
            <div class="card-header-actions">
              <b-button @click="save()" class="ml-2" size="sm" variant="success">저장</b-button>
              <b-button @click="undo()" class="ml-2" size="sm" variant="danger">되돌리기</b-button>
            </div>
          </div>
          <div id="hot-preview">
            <hot-table :settings="hotSettings" class="overflow:'hidden'" ref="hotTable"></hot-table>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {HotTable} from '@handsontable/vue';

export default {
  name: 'OriginMapping',
  title: '원산지 분류',
  components: {HotTable},
  data() {
    return {
      hotItems: [],
      filteredHotItems: [],
      filteredCategories: [],
      originSearch: "",
      hotSettings: {
        autoWrapCol: true,
        autoWrapRow: false,
        colHeaders: 'country_code,country_en,country_kor,key_word1,key_word2,key_word3,key_word4,key_word5,key_word6,key_word7'.split(','),
        columns: [ // 이 전체가 하나의 obj(하나의 obj가 hot-table의 하나의 row)
          {data: 'country_code', readOnly: true},
          {data: 'country_en', readOnly: true},
          {data: 'country_kor', readOnly: true},
          // {data: 'key_word'},
          {data: 'key_word1'},
          {data: 'key_word2'},
          {data: 'key_word3'},
          {data: 'key_word4'},
          {data: 'key_word5'},
          {data: 'key_word6'},
          {data: 'key_word7'},
        ],
        colWidths: [200, 150, 150, 150, 150, 150, 150, 150, 150, 150],
        width: '100%',
        height: 650,
        rowHeaders: true,
        licenseKey: 'non-commercial-and-evaluation',
        columnSorting: true,
        stretchH: "all",
      },
    };
  },
  async created() {
    let j = await this.$api.getJson('/meta/originMapping/mappingOrigin');
    this.hotItems = j.hotItems;
    this.filteredHotItems = this.hotItems;
    this.$refs.hotTable.hotInstance.loadData(this.hotItems);
  },
  methods: {
    async save() {
      let data = this.$refs.hotTable.hotInstance.getData();
      let j = await this.$api.postJson('/meta/originMapping/saveOrigin', {
        hotItems: data,
      });
      if (!j) {
        alert("에러");
        return;
      }
      alert("저장되었습니다");
    },
    async undo() {
      if (confirm('테이블을 되돌리기 하겠습니까?')) {
        this.$refs.hotTable.hotInstance.loadData(this.hotItems);
      }
    },

    async search() {
      let data = this.hotItems;
      this.filteredHotItems = [];
      const re = new RegExp(this.originSearch, 'i');
      data.forEach(row => {
        if ((row.country_kor || '').match(re) || (row.country_en || '').match(re) || (row.country_code || '').match(re)) {
          this.filteredHotItems.push(row);
        }
      });
      this.$refs.hotTable.hotInstance.loadData(this.filteredHotItems);
    },
  },
}
</script>
<style>
@import '~handsontable/dist/handsontable.full.css';
</style>
